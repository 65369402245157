import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetStatesProvincesService {

  constructor() { }

  GetStatesProvinces(countryId: number): StateProvince[] {
    return [
      {
        "ProvinceStateId": 0,
        "Label": "Unavailable",
        "CountryId": 0
      },
      {
        "ProvinceStateId": 14049,
        "Label": "Alabama",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14050,
        "Label": "Alaska",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14051,
        "Label": "Alberta",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14052,
        "Label": "Arizona",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14053,
        "Label": "Arkansas",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14054,
        "Label": "British Columbia",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14055,
        "Label": "California",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14056,
        "Label": "Colorado",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14057,
        "Label": "Connecticut",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14058,
        "Label": "Delaware",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14059,
        "Label": "Florida",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14060,
        "Label": "Georgia",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14061,
        "Label": "Hawaii",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14062,
        "Label": "Idaho",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14063,
        "Label": "Illinois",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14064,
        "Label": "Indiana",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14065,
        "Label": "Iowa",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14066,
        "Label": "Kansas",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14067,
        "Label": "Kentucky",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14068,
        "Label": "Louisiana",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14069,
        "Label": "Maine",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14070,
        "Label": "Manitoba",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14071,
        "Label": "Maryland",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14072,
        "Label": "Massachusetts",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14073,
        "Label": "Michigan",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14074,
        "Label": "Minnesota",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14075,
        "Label": "Mississippi",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14076,
        "Label": "Missouri",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14077,
        "Label": "Montana",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14078,
        "Label": "Nebraska",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14079,
        "Label": "Nevada",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14080,
        "Label": "New Brunswick",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14081,
        "Label": "New Hampshire",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14082,
        "Label": "New Jersey",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14083,
        "Label": "New Mexico",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14084,
        "Label": "New York",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14085,
        "Label": "Newfoundland",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14086,
        "Label": "North Carolina",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14087,
        "Label": "North Dakota",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14088,
        "Label": "Northern Territories",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14089,
        "Label": "Nova Scotia",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14090,
        "Label": "Nunavut",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14091,
        "Label": "Ohio",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14092,
        "Label": "Oklahoma",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14093,
        "Label": "Ontario",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14094,
        "Label": "Oregon",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14095,
        "Label": "Pennsylvania",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14096,
        "Label": "Prince Edward Island",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14097,
        "Label": "Quebec",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14098,
        "Label": "Rhode Island",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14099,
        "Label": "Saskatchewan",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14100,
        "Label": "South Carolina",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14101,
        "Label": "South Dakota",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14102,
        "Label": "Tennessee",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14103,
        "Label": "Texas",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14104,
        "Label": "Utah",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14105,
        "Label": "Vermont",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14106,
        "Label": "Virginia",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14107,
        "Label": "Washington",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14108,
        "Label": "West Virginia",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14109,
        "Label": "Wisconsin",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14110,
        "Label": "Wyoming",
        "CountryId": 2
      },
      {
        "ProvinceStateId": 14111,
        "Label": "Yukon Territories",
        "CountryId": 1
      },
      {
        "ProvinceStateId": 14112,
        "Label": "District of Columbia",
        "CountryId": 2
      }
    ].filter((stateProvince: StateProvince) => {
      return stateProvince.CountryId === countryId;
    });
  }
}

export interface StateProvince {
    ProvinceStateId: number;
    Label: string;
    CountryId: number;
}
