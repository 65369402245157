import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WarrantyInformation} from "./warranty-information";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WarrantyInformationService {

  constructor(private httpClient: HttpClient) { }

  getWarrantyInformation(warrantyId: number): Observable<WarrantyInformation> {
    return this.httpClient.get<WarrantyInformation>(`${environment.apiServer}/WarrantyInformation`, {params: {warrantyId: warrantyId}});
  }

}
