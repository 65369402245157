import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SetManuallyEnteredDeductibleService {

  constructor(private httpClient: HttpClient) { }

  setManuallyEnteredDeductible(claimId: number, manuallyEnteredDeductible: number | null | undefined) {
    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/SetManuallyEnteredDeductible`, {
      claimId,
      manuallyEnteredDeductible
    });
  }
}
