<div #statsBarElement class="position-fixed bg-white start-0 w-100 shadow-lg">
  <app-spinner [show]="loading" [center]="true"></app-spinner>
  <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-auto row-cols-xxl-auto justify-content-xxl-between lh-md" [style.display]="dealerStats && repairCentreStats ? '' : 'none'">
    <div class="col-sm-12 col-lg-4 col-xl-4 col-xxl-auto address pe-4 mb-2 mb-xxl-0">
      <div class="h4 text-black-50">Dealer</div>
      <a class="fw-bolder" href="https://e2.eazeetrak.com/dealers;dealerId={{claimDto!.vendor.vendorId}}/dealer-info?c=contact" target="_blank">{{claimDto!.vendor.friendlyName}}<i class="ms-1" data-feather="external-link"></i></a><br>
      {{claimDto!.vendor.address}}<br>
      {{claimDto!.vendor.city}}, {{claimDto!.vendor.provinceState}}
    </div>
    <div class="mt-auto mb-2 mb-xxl-0 px-xxl-0">
      <div class="h3 text-body mb-0">$ {{dealerStats?.activeClaims ?? 0 | number: '1.0-0'}}</div>
      <label class="mt-n1">Active Claims</label>
    </div>
    <div class="mt-auto mb-2 mb-xxl-0 px-xxl-0">
      <div class="h3 text-body mb-0">$ {{dealerStats?.expiredClaims ?? 0 | number: '1.0-0'}}</div>
      <label class="mt-n1">Expired Claims</label>
    </div>
    <div class="mt-auto mb-2 mb-xxl-0 px-xxl-0">
      <div class="h3 text-body mb-0">$ {{dealerStats?.activeSales ?? 0 | number: '1.0-0'}}</div>
      <label class="mt-n1">Active Sales</label>
    </div>
    <div class="mt-auto mb-2 mb-xxl-0 px-xxl-0">
      <div class="h3 text-body mb-0">$ {{dealerStats?.prev6MonthsSales ?? 0 | number: '1.0-0'}}</div>
      <label class="mt-n1">Prev 6 Month Sales</label>
    </div>
    <div class="mt-auto mb-2 mb-xxl-0 px-xxl-0">
      <div class="h3 text-body mb-0">{{dealerStats?.ultimateLossRatio ?? 0 | percent: '1.1-1'}}</div>
      <label class="mt-n1">Ultimate Loss Ratio</label>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-xxl-none border-1 border-top border-gray-500 opacity-75 pb-2"></div>
    <div class="col-sm-12 col-lg-4 col-xl-4 col-xxl-auto d-xxl-flex mb-2 mb-sm-0 px-xxl-0">
      <div class="d-none d-xxl-block border-start border-1 border-gray-500 opacity-75 pe-3"></div>
      <div class="address mt-auto pe-4 mb-2 mb-lg-0">
        <div class="h4 text-black-50">Repair Center</div>
        <a class="fw-bolder" href="https://e2.eazeetrak.com/repair-centre/{{claimDto!.repairCentre.repairCentreId}}" target="_blank" >{{claimDto!.repairCentre.name}}<i class="ms-1" data-feather="external-link"></i></a> / <a href="mailto:{{claimDto!.submittedBy.email}}">{{claimDto!.submittedBy.firstName}} {{claimDto!.submittedBy.lastName}}</a><br>
        {{claimDto!.repairCentre.address}}<br>
        {{claimDto!.repairCentre.city}}, {{claimDto!.repairCentre.province}}
      </div>
    </div>
    <div class="mt-auto px-xxl-0">
      <div class="h3 text-body mb-0">$ {{repairCentreStats?.claimsTotal ?? 0 | number: '1.0-0'}}</div>
      <label class="mt-n1">Total Claims</label>
    </div>
    <div class="mt-auto ps-xxl-0">
      <div class="h3 text-body mb-0">$ {{repairCentreStats?.claimsTotalLast6Months ?? 0 | number: '1.0-0'}}</div>
      <label class="mt-n1">Last 6 Months</label>
    </div>
  </div>
  <p class="text-center my-3" [style.display]="error ? 'block' : 'none'">An error occurred while loading dealer and repair center statistics.</p>
</div>
