<button
  class="btn btn-lg btn-primary position-relative lh-md w-100"
  type="button"
  (click)="updateClaim()"
  [disabled]="!isAdjusterView && (!repairTotal || isLocked)">
    <span
      class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
      role="status" aria-hidden="true" [ngClass]="{'show': busySubmitting}">
    </span>
  <span class="text fade" [ngClass]="{'show': !busySubmitting}">Update Claim</span>
</button>
