import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SetIsClaimTestDataService {

  constructor(private httpClient: HttpClient) {
  }

  setIsClaimTestData(claimId: number, isClaimTestData: boolean) {

    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/SetIsClaimTestData`, {
      claimId,
      isClaimTestData
    });
  }

}
