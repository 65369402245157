<ng-container *ngIf="warranty">
  <p class="text-uppercase fs-4 fw-bolder m-0 lh-sm">{{IsAdjusterView ? warranty.label : warranty.terseLabel}}</p>
  <p class="lh-sm mb-0 mt-1">
    <ng-container *ngIf="IsAdjusterView">
      <span
        class="badge badge-lg rounded-pill me-3"
        [ngClass]="{'bg-danger': warranty.status === 'Expired', 'bg-success': warranty.status === 'Active', 'bg-primary': warranty.status !== 'Expired' && warranty.status !== 'Active'}">
        {{warranty.status}}
      </span>
      <span class="me-3 opacity-75">Expiry {{warranty.expiryDate | date:'longDate' }}</span>
    </ng-container>
    <span class="opacity-75 d-inline-block d-xl-block d-xxl-inline-block mt-0 mt-xl-1 mt-xxl-0" *ngIf="warranty?.deductible !== null">${{warranty.deductible ?? 0}} Deductible</span>
  </p>
</ng-container>
