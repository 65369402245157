import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UpdateAuthorizationNumberService {

  constructor(private httpClient: HttpClient) {
  }

  updateClaimAuthorizationNumber(claimId: number, authorizationNumber: string | null) {

    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/UpdateAuthorizationNumber`, {
      claimId,
      authorizationNumber
    });
  }

}
