import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DeleteClaimServiceService {

  constructor(private httpClient: HttpClient) { }


  DeleteClaim(claimId: number) {
    return this.httpClient.delete(`${environment.apiServer}/DeleteClaim`, {params: {claimId: claimId}});
  }
}
