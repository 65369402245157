import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartupComponent implements OnInit {

  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {
  }

  ngOnInit(): void {
    this.oidcSecurityService.getUserData().subscribe((userData) => {
      if (userData.UserType === 'GlobalWarrantyEmployee') {
        this.router.navigate(['/adjuster/claim-list']);
      }
      else {
        this.router.navigate(['/claim-list']);
      }
    });
  }


}
