import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  jwtHelper: any;
  static token: any = null;
  static encodedToken: string = '';

  constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) {
    this.jwtHelper = new JwtHelperService();
    this.oidcSecurityService.getAccessToken().subscribe(t => {
      AccountService.token = this.jwtHelper.decodeToken(t);
      AccountService.encodedToken = t;
    });
  }

  public getEncodedToken(): string {
    return AccountService.encodedToken;
  }

  public getToken(): any {
    return AccountService.token;
  }

  public getUserName(): string {
    return this.getToken().FirstName + ' ' + this.getToken().LastName;
  }

  public getUserId(): string {
    return this.getToken().UserId;
  }

  public getUserRoles(): string[] {
    let roles = this.getToken().Roles;
    if(!roles) {
      return [];
    }
    if(typeof roles == "string") {
      return [roles];
    }
    return roles;
  }

  public isInRole(role: string): boolean {
    let userRoles = this.getUserRoles();
    for(let iRole = 0; iRole < userRoles.length; ++iRole) {
      if(userRoles[iRole].toLowerCase() === role.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  public isAdjuster(): boolean {
    return this.isInRole("GlobalWarrantyClaimsAdjuster");
  }

  public isClaimsManager(): boolean {
    return this.isInRole("GlobalWarrantyClaimsManager");
  }

  isAuthenticated(): boolean {
    return true;
  }

  public isAssistantClaimsManager(): boolean {
    return this.isInRole('GlobalWarrantyAssistantClaimsManager')
  }

  logout() {
    this.oidcSecurityService.logoff();
  }
}
