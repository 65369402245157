import {Component, OnInit, Renderer2} from '@angular/core';
import * as feather from "feather-icons";
import AjaxSettings = DataTables.AjaxSettings;
import {ListClaimsService} from "./services/list-claims.service";

@Component({
  selector: 'app-claims-list',
  templateUrl: './claims-list.component.html',
  styleUrls: ['./claims-list.component.scss']
})
export class ClaimsListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};

  constructor(
    private listClaimsService: ListClaimsService,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(document.body, 'adjuster');
    this.renderer.addClass(document.body, 'claim-list');
    this.renderer.removeClass(document.body, 'claim');
  }

  ngOnInit(): void {
    this.dtOptions = {
      dom: '<"row"<"col-md-6"i><"col-md-6"f>><"row"<"px-0"t>><"row"<"col-6 mt-2"l><"col-6"p>>',
      language: {
        info: 'Showing _START_ to _END_ of _TOTAL_ claims',
        infoEmpty: 'Showing 0 claims',
        infoFiltered: '<br>Filtered from _MAX_ total claims',
        zeroRecords: 'No matching claims found',
        lengthMenu: 'Show _MENU_ claims',
        loadingRecords: '<div class="d-flex center" style="width: 100%"><div class="spinner-border text-primary" style="margin: auto" role="status"><span class="sr-only">Loading...</span></div></div>',
      },
      lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All']
      ],
      pageLength: 25,
      order: [[0, 'desc']],
      processing: true,
      ajax: (ajaxSettings: AjaxSettings, callback) => {
        this.listClaimsService.listClaims
        (
          ajaxSettings, {}
        ).subscribe({
            next: (resp: any) => {
              callback({
                  recordsTotal: resp.length,
                  recordsFiltered: resp.length,
                  data: resp
                }
              )

            },
            error: () => {
              this.errorOccurred = true;
            }
          }
        );
      },
      columns: [
        {
          title: 'Date',
          data: 'submittedDateTime',
          className: 'ps-3 w-auto',
          render: (data, type) => {
            if (type === 'sort') {
              return data;
            } else {
              const date = new Date(data);
              const dateStr = date.toLocaleDateString("en-us", {weekday: "short", year: "numeric", month: "short", day: "numeric"});
              const timeStr = date.toLocaleTimeString("en-us", {hour12: true, hour: "numeric", minute: "2-digit"})
              return `<span class="text-nowrap">${dateStr}</span> <span class="text-nowrap">${timeStr}</span>`;
            }
          }
        },
        {
          title: 'Type',
          data: 'coverageType',
          className: 'text-nowrap',
          render: (data) => {
            return data;
          }
        }, {
          title: '<span class="text-nowrap">Repair Center</span>',
          data: 'repairCentreName',
          className: 'w-auto',
          render: (data) => {
            return data;
          }
        }, {
          title: 'User',
          data: 'submittedBy',
          className: 'w-auto',
          render: (data) => {
            return data.firstName + ' ' + data.lastName;
          }
        }, {
          title: 'Customer',
          data: 'customerName',
          className: 'w-auto'
        }, {
          title: 'VIN',
          data: 'vin'
        }, {
          title: 'Vehicle',
          data: 'ymmt',
          className: 'w-auto'
        }, {
          title: 'Status',
          data: 'status',
          render: (data) => {
            let statusClass = '';
            switch (data.toLowerCase()) {
              case 'draft':
                statusClass = 'secondary';
                break;
              case 'submitted':
                statusClass = 'primary';
                break;
              case 'viewed':
                data = 'Submitted'
                statusClass = 'primary';
                break;
              case 'pre-approved':
              case 'accepted':
              case 'approved':
                statusClass = 'success';
                break;
              case 'rejected':
                statusClass = 'danger';
                break;
              case 'deleted':
                statusClass = 'black';
                break;
              default:
                statusClass = 'primary';
            }
            return `<span class="badge bg-${statusClass}">${data}</span>`;
          }
        }, {
          title: 'Adjuster',
          data: 'assignedToName',
          render: (data) => {
            return data;
          }
        }, {
          title: '',
          data: '',
          searchable: false,
          orderable: false,
          className: 'text-end text-nowrap align-middle py-0 pe-3',
          render: (data, type, row) => {
            return `<a class="btn btn-link border-0 p-0 ms-2" href="/adjuster/claim/${row.claimId}" ngbTooltip="Edit"><i data-feather="edit-2"></i></a>`;
          }
        }
      ],
      drawCallback: function () {
        feather.replace();
      }
    };

  }
  errorOccurred = false;

}
