<div *ngIf="claimDto" class="position-fixed sticky-top start-0 w-100 shadow-lg border-bottom d-none d-lg-block visually-hidden">
  <div class="container-fluid bg-primary-dark text-light d-block d-md-flex">
    <app-vehicle-information
      [claim]="claimDto"
      class="d-block d-xl-flex">
    </app-vehicle-information>
    <div class="border-start border-white-35 d-none d-md-flex ps-md-4"></div>
    <app-warranty-information
      [warranty]="claimDto!.warranty"
      [IsAdjusterView]="true"
      class="pt-3 pt-1 pt-md-1">
    </app-warranty-information>
  </div>
  <div class="container-fluid bg-stat">
    <div class="row">
      <app-assign-to [claimDto]="claimDto" class="col-sm-6 col-md-4 col-xl-3 mt-auto mb-3 mb-md-0"></app-assign-to>
      <app-claim-authorization-number [claimDto]="claimDto" class="col-sm-6 col-md-2 col-xl-2 mt-auto mb-3 mb-md-0"></app-claim-authorization-number>
      <app-status [claimDto]="claimDto" class="col-sm-6 col-md-2 col-xl-2 mt-auto mb-3 mb-sm-0"></app-status>
      <app-deductible *ngIf="claimDto.warranty?.deductible !== null" [claimDto]="claimDto" [minimalUi]="true" class="col-sm-6 col-md-2 col-xl-2 mt-auto mb-3 mb-sm-0"></app-deductible>
      <div class="col-sm-6 offset-sm-6 col-md-2 offset-md-0 col-xl-2 offset-xl-1 mt-sm-3 mt-md-auto">
        <app-is-claim-test-data [ClaimDto]="claimDto!" class="d-block mb-1"></app-is-claim-test-data>
        <app-update-button
          [repairTotal]="repairTotal"
          [isLocked]="isLocked"
          [busySubmitting]="busySubmitting"
          [isAdjusterView]="true"
          (updateButtonClicked)="updateClaim()"
          >
        </app-update-button>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-light border-top">
    <ul class="text-end lh-md">
      <li>
        <span>Repair Total</span>
        <span class="fs-3">{{repairTotal | currency}}</span>
      </li>
      <li *ngIf="claimDto.warranty?.deductible !== null">
        <span>Deductible</span>
        <span class="fs-3" *ngIf="claimDto">{{claimDto.manuallyEnteredDeductible ?? claimDto.warranty.deductible | currency}}</span>
      </li>
      <li *ngIf="claimDto.warranty?.deductible !== null">
        <span>Subtotal</span>
        <span class="fs-3">{{subTotal | currency}}</span>
      </li>
      <li *ngIf="pst">
        <span>PST</span>
        <span class="fs-3">{{pst | currency}}</span>
      </li>
      <li *ngIf="pst">
        <span>GST</span>
        <span class="fs-3">{{gst | currency}}</span>
      </li>
      <li *ngIf="tax">
        <span>{{claimDto!.tax.label!}}</span>
        <span class="fs-3">{{tax | currency}}</span>
      </li>
      <li>
        <span class="fw-bold">Total</span>
        <span class="fs-2 text-dark">{{total | currency}}</span>
      </li>
    </ul>
  </div>
</div>
