import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnChanges {
  @Input() public show : any = false;
  @Input() center = false;
  @Input() delay = 500;
  delayExpired = false;


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!this.show) {
      this.delayExpired = false;
      return;
    }

    setTimeout(() => {
      this.delayExpired = this.show;
    }, this.delay);
  }


}
