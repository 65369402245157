import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UpdateInternalNotesService {

  constructor(private httpClient: HttpClient) {
  }

  updateInternalNotes(claimId: number, internalNotes: string | null) {

    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/UpdateInternalNotes`, {
      claimId,
      internalNotes
    });
  }

}
