import {Component, OnInit, Output} from '@angular/core';
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {AccountService} from "./auth/account-service.service";
import {Router} from "@angular/router";
import {BuildVersion} from "./build-version/build-version";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  private userFullName: any;

  constructor(public oidcSecurityService: OidcSecurityService, private accountService: AccountService, private router: Router) {
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe(
        ({ isAuthenticated, userData, accessToken, idToken, configId }) => {
          console.log('callback authenticated', isAuthenticated);
        }
      );
  }

  protected readonly BuildVersion = BuildVersion;
}
