import { Injectable } from '@angular/core';
import { DecimalPipe } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class DownloadCsvService {

  constructor(private _decimalPipe: DecimalPipe) { }

  downloadAdjusterData(adjusterData: any, filename: string): void {
    const csvHeader = ['Adjuster Name', 'Accepted Time', 'Rejected Time', 'Accepted Claims', 'Rejected Claims', 'Unresolved Claims', 'Total Claims'];
    let csvData = `"${csvHeader.join('","')}"\r\n`;

    for (let adjuster of adjusterData.adjusters) {
      const csvRow = [
        this.parseValue(`${adjuster.firstName} ${adjuster.lastName}`),
        `${this._decimalPipe.transform(adjuster.acceptedMinutes / 60, '1.1-1')} h`,
        `${this._decimalPipe.transform(adjuster.rejectedMinutes / 60, '1.1-1')} h`,
        `${this._decimalPipe.transform(adjuster.acceptedCount, '1.0-0')}`,
        `${this._decimalPipe.transform(adjuster.rejectedCount, '1.0-0')}`,
        `${this._decimalPipe.transform(adjuster.unresolvedCount, '1.0-0')}`,
        `${this._decimalPipe.transform(adjuster.assignedCount, '1.0-0')}`
      ];
      csvData += `"${csvRow.join('","')}"\r\n`;
    }
    const csvFooter = [
      'Total',
      `${this._decimalPipe.transform(adjusterData.acceptedMinutes / 60, '1.1-1')} h`,
      `${this._decimalPipe.transform(adjusterData.rejectedMinutes / 60, '1.1-1')} h`,
      `${this._decimalPipe.transform(adjusterData.acceptedCount, '1.0-0')}`,
      `${this._decimalPipe.transform(adjusterData.rejectedCount, '1.0-0')}`,
      `${this._decimalPipe.transform(adjusterData.unresolvedCount, '1.0-0')}`,
      `${this._decimalPipe.transform(adjusterData.total, '1.0-0')}`,
    ];
    csvData += `"${csvFooter.join('","')}"\r\n`;

    this.downloadCsv(csvData, filename);
  }

  downloadRepairCenterData(repairCenterData: any, filename: string): void {
    const csvHeader = ['Name', 'City', 'Province / State', 'Number of Claims', 'Retail Amount'];
    let csvData = `"${csvHeader.join('","')}"\r\n`;

    for (let repairCenter of repairCenterData.repairCentres) {
      const csvRow = [
        this.parseValue(repairCenter.name),
        this.parseValue(repairCenter.city),
        this.parseValue(repairCenter.province),
        `${this._decimalPipe.transform(repairCenter.total, '1.0-0')}`,
        `${this._decimalPipe.transform(repairCenter.retailTotal,'1.2-2')?.replace(',', '')}`
      ];
      csvData += `"${csvRow.join('","')}"\r\n`;
    }
    const csvFooter = [
      'Total',
      '',
      '',
      `${this._decimalPipe.transform(repairCenterData.total, '1.0-0')}`,
      `${this._decimalPipe.transform(repairCenterData.retailTotal, '1.2-2')?.replace(',', '')}`
    ];
    csvData += `"${csvFooter.join('","')}"\r\n`;

    this.downloadCsv(csvData, filename);
  }

  downloadCsv = (csvData: string, filename: string) => {
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      downloadLink.setAttribute("target", "_blank");
    }
    downloadLink.setAttribute("href", url);
    downloadLink.setAttribute("download", filename + ".csv");
    downloadLink.style.visibility = "hidden";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  parseValue(value: any): string {
    if (value == null) {
      return '';
    } else {
      value = value.replace(/&#39;/g, '\'');
      value = value.replace(/"/g, '""');
      return value;
    }
  }
}
