<ul class="price-totals">
  <li class="price-item">
    <span>Repair Total</span><span class="fs-3">{{repairTotal | currency}}</span>
  </li>
  <li class="price-item" *ngIf="claimDto.warranty?.deductible !== null">
    <span>Deductible</span>
    <span class="fs-3" *ngIf="claimDto">{{claimDto.manuallyEnteredDeductible ?? claimDto.warranty.deductible | currency}}</span>
  </li>
  <li class="price-line" *ngIf="claimDto.warranty?.deductible !== null"></li>
  <li class="price-item" *ngIf="claimDto.warranty?.deductible !== null"><span>Subtotal</span><span class="fs-3">{{subTotal | currency}}</span></li>
  <li class="price-spacer"></li>
  <li class="price-item" *ngIf="pst"><span>PST</span><span class="fs-3">{{pst | currency}}</span></li>
  <li class="price-item" *ngIf="gst"><span>GST</span><span class="fs-3">{{gst | currency}}</span></li>
  <li class="price-item" *ngIf="tax"><span>{{claimDto.tax!.label}}</span><span class="fs-3">{{tax | currency}}</span></li>
  <li class="price-line"></li>
  <li class="price-item"><span class="fw-bold">Total</span><span class="fs-2 text-dark">{{total | currency}}</span></li>
</ul>
