import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UpdateDateOfOccurrenceService} from "./services/update-date-of-occurrence.service";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-date-of-occurrence',
  templateUrl: './date-of-occurrence.component.html',
  styleUrls: ['./date-of-occurrence.component.scss']
})
export class DateOfOccurrenceComponent implements OnChanges, OnInit {

  @Input() claimId!: number;
  @Input() loadedDateOfOccurrence!: null | undefined | (Date | string);
  @Input() claimCreatedDateTime!: Date | string | undefined;
  dateOfOccurrence: NgbDateStruct | null = null;
  minDate: Date = new Date();
  maxDate: Date = new Date();

  constructor(private updateDateOfOccurrenceService: UpdateDateOfOccurrenceService) {
  }

  ngOnInit() {
    let claimCreatedDate = new Date(this.claimCreatedDateTime!);
    this.minDate.setDate(claimCreatedDate.getDate() - 7);
    this.maxDate = claimCreatedDate;
  }

  updateDateOfOccurrence() {
    this.updateDateOfOccurrenceService.updateDateOfOccurrence(this.claimId, new Date(this.dateOfOccurrence?.year + '-' + this.dateOfOccurrence?.month + '-' + this.dateOfOccurrence?.day)).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedDateOfOccurrence) {
       let dateParts = new Date(this.loadedDateOfOccurrence!).toISOString().split('T')[0].split('-');
       this.dateOfOccurrence = {year: +dateParts[0], month: +dateParts[1], day: +dateParts[2]};
    }
  }
}
