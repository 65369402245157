<div class="modal-header">
  <h4 class="modal-title ms-3">Claim Has Been Updated</h4>
</div>

<div class="modal-body py-4">
  <div class="container-fluid px-3">
    <div class="alert alert-success alert-outline-coloured">
      <div class="alert-icon">
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
      </div>
      <div class="alert-message">
        <strong>Success!</strong><br>
        This claim has been updated.
      </div>
    </div>
    <div class="text-center mt-4 mb-3">
      <a class="btn btn-primary me-3" (click)="returnToClaimsList()"><i class="me-2" data-feather="corner-down-left"></i>Back to Claims List</a>
      <button class="btn btn-primary" (click)="viewClaim()"><i class="me-2" data-feather="eye"></i>View This Claim</button>
    </div>
  </div>
</div>
