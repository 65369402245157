import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UpdateTowingService {
  constructor(private httpClient: HttpClient) {
  }

  updateTowing(claimId: number, towing: number | null) {
    return this.httpClient.put<void>(`${environment.apiServer}/Claim/UpdateTowing`, {claimId, towing});
  }
}
