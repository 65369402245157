<div class="row mb-3">
  <div class="col-auto">
    <h1 class="h3 mt-3 mb-0">Claims List</h1>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive" *ngIf="!errorOccurred">
          <table datatable [dtOptions]="dtOptions" class="table table-sm table-striped table-hover lh-md w-100"></table>
        </div>
        <p *ngIf="errorOccurred">An error occurred while loading the Claims information.  Click here to retry.  <button (click)="errorOccurred = false;">Retry</button></p>
      </div>
    </div>
  </div>
</div>
