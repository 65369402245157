import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UpdateOdometerService {
  constructor(private httpClient: HttpClient) {
  }

  updateOdometer(claimId: number, reading: number, units: string) {
    return this.httpClient.put<void>(`${environment.apiServer}/Claim/UpdateOdometer`, {claimId, reading, units});
  }
}
