<hr class="mb-1 mt-0 text-black">
<div class="mb-4">
  <span class="h4">Messages</span>
  <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#messagesExpandCollapse" aria-expanded="true" aria-controls="messagesExpandCollapse">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
  </button>
</div>
<div id="messagesExpandCollapse" class="collapse show mb-5">
  <ul class="timeline messages">
    <li class="timeline-item mb-3">
      <span class="text-uppercase me-1">To:</span> <strong>{{isAdjusterView ? claimDto!.repairCentre.name : 'Global Warranty'}}</strong> / <span>{{toName}}</span>
      <textarea [disabled]="newMessageInTransit" [(ngModel)]="newMessage" class="form-control mt-1 mb-3" rows="3"></textarea>
      <button (click)="sendMessage()" role="button" [disabled]="newMessage === '' || newMessageInTransit" id="sendMessageButton" class="btn btn-outline-primary position-relative">
            <span
              [ngClass]="newMessageInTransit ? 'show' : ''"
              class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
              role="status" aria-hidden="true"></span>
        <span [ngClass]="newMessageInTransit ? '' : 'show'" class="text fade">Send Message to {{isAdjusterView ? claimDto!.repairCentre.name : 'Global Warranty'}}</span>
      </button>
      <p *ngIf="sendingErrorOccurred">An error occurred while sending.  Please try again.</p>
    </li>
    <li *ngFor="let message of messages; let i = index" class="timeline-item mb-3">
      <span class="text-uppercase me-1">From:</span> <strong>{{message.senderName}}<span *ngIf="message.isMessageFromGlobalWarrantyEmployee"> (Global Warranty)</span></strong>
      <small class="d-block">{{message.createdDateTime | date:'full' }}</small>
      <p class="message-content my-1" [ngClass]="{'collapsed': !isMessageExpanded(message)}" #messageContentItem>
        {{message.message}}
      </p>
      <button class="btn btn-link p-0" [ngClass]="{'d-none': !isMessageTruncated(i)}" type="button" (click)="showHideMessage(message)">Show {{!isMessageExpanded(message) ? 'More' : 'Less'}}</button>
    </li>
  </ul>
</div>
