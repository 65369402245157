import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CreateClaimService {

  constructor(private httpClient: HttpClient) { }

  public CreateClaim(warrantyId: number): Observable<number> {
    return this.httpClient.post<number>(`${environment.apiServer}/CreateClaim`, null, {params: {warrantyId: warrantyId}});
  };
}
