import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SaveInitialMessageFromRepairCentreToGlobalService {

  constructor(private httpClient: HttpClient) { }

  saveInitialMessageFromRepairCentreToGlobal(claimId: number, message: string) {
    return this.httpClient.put(`${environment.apiServer}/SaveInitialMessageFromRepairCentreToGlobal`, {claimId: claimId, message: message});
  }
}
