<div class="modal-header">
  <h4 class="modal-title ms-3">Delete Repair Work Item</h4>
</div>

<div class="modal-body py-4">
  <div class="container-fluid px-3">
    <p class="mb-3"><strong>Do you wish to remove this repair work item?</strong></p>
    <table class="table table-sm mb-0">
      <tbody>
      <tr>
        <th class="text-end text-nowrap w-25 ps-0">Repair Cost</th>
        <td class="w-75">{{repair!.total | currency}}</td>
      </tr>
      <tr>
        <th class="text-end text-nowrap w-25 ps-0">Part Type</th>
        <td class="w-75">{{part?.label}}</td>
      </tr>
      <tr>
        <th class="text-end border-bottom-0 w-25 ps-0">Description</th>
        <td class="border-bottom-0 w-75">
          <div class="description">{{repair!.description}}</div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <div class="px-3">
    <button class="btn btn-secondary me-3" (click)="cancelDelete()">Cancel</button>
    <button class="btn btn-danger position-relative" type="button" (click)="deleteRepairItem()">
      <span
        class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto"
        role="status"
        aria-hidden="true"
        [ngClass]="{'show': busyDeleting}">
      </span>
      <span class="text fade show" [ngClass]="{'show': !busyDeleting}">
        <i class="me-2" data-feather="trash"></i>
        Delete Repair Item
      </span>
    </button>
  </div>
</div>
