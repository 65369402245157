<ng-container *ngIf="!ClaimStats">Loading ...</ng-container>

<ng-container *ngIf="ClaimStats">
  <div class="row mb-3">
    <div class="col-auto">
      <h1 class="h3 mb-2">
        Claims Overview
      </h1>
    </div>
    <div class="col-auto ms-auto text-end mt-n2">
      <div class="ngx-daterangepicker-action form-date-range-picker position-relative overflow-hidden form-control d-inline-block w-auto shadow-sm mb-2 border" (click)="openDateRangePicker()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-middle me-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
        <span>{{dateRangePicker.startDate?.format('MMMM D, YYYY')}} &mdash; {{dateRangePicker.endDate?.format('MMMM D, YYYY')}}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down align-middle"><polyline points="6 9 12 15 18 9"></polyline></svg>
        <input
          type="text"
          ngxDaterangepickerBootstrap
          [(ngModel)]="dateRangePicker"
          class="visually-hidden position-absolute bottom-0 end-0"
          [timePicker]="false"
          [showCustomRangeLabel]="true"
          [showCancel]="true"
          [linkedCalendars]="true"
          [alwaysShowCalendars]="false"
          [showRangeLabelOnInput]="false"
          [keepCalendarOpeningWithRange]="true"
          [drops]="'down'"
          [opens]="'left'"
          [ranges]="ranges"
          [locale]="locale"
          (datesUpdated)="datesUpdatedRange($event)"
        />
      </div>
    </div>
  </div>

  <div class="row">

    <div class="col-sm-12 col-md-6 col-lg-3 col-xxl-2 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0 pe-1">
              <h2 class="card-title">Submitted Claims <i data-feather="chevron-down"></i></h2>
            </div>
            <div class="col-auto ms-auto ps-0">
              <div class="d-flex stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-file">
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>
              </div>
            </div>
          </div>
          <div class="d-inline-block my-0">
            <span id="submittedCount" class="display-6 text-info pe-1">{{ ClaimStats.submittedClaims.count }}</span>
            <span id="submittedAmount"
                  class="h3">{{ ClaimStats.submittedClaims.totalCost | currency : '' : 'symbol' : '1.0-0' }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm">Claims submitted within date range</div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-3 col-xxl-2 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0 pe-1">
              <h2 class="card-title">Unresolved Claims</h2>
            </div>
            <div class="col-auto ms-auto ps-0">
              <div class="d-flex stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-eye">
                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </div>
            </div>
          </div>
          <div class="d-inline-block my-0">
            <span id="submittedInUnresolvedCount"
                  class="display-6 text-danger pe-1">{{ ClaimStats.unresolvedClaims.count }}</span>
            <span id="submittedInUnresolvedAmount"
                  class="h3">{{ ClaimStats.unresolvedClaims.totalCost | currency : '' : 'symbol' : '1.0-0' }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm mb-3">Submitted within date range but not yet resolved</div>
          <div class="d-inline-block my-0">
            <span id="totalUnresolvedCount"
                  class="display-6 text-danger pe-1">{{ ClaimStats.unresolvedClaims.totalCount }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm">Total unresolved claims that have not been accepted, rejected, or
            closed
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-3 col-xxl-2 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0 pe-1">
              <h2 class="card-title">Accepted Claims</h2>
            </div>
            <div class="col-auto ms-auto ps-0">
              <div class="d-flex stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-check">
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
          </div>
          <div class="d-inline-block my-0">
            <span id="submittedInAcceptedCount"
                  class="display-6 text-success pe-1">{{ ClaimStats.approvedClaims.count }}</span>
            <span id="submittedInAcceptedAmount"
                  class="h3">{{ ClaimStats.approvedClaims.totalCost | currency : '' : 'symbol' : '1.0-0' }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm mb-3">Submitted and accepted within date range</div>
          <div class="d-inline-block my-0">
            <span id="submittedOutAcceptedCount"
                  class="display-6 text-success pe-1">{{ ClaimStats.approvedClaims.submittedOutsideDateRangeCount }}</span>
            <span id="submittedOutAcceptedAmount"
                  class="h3">{{ ClaimStats.approvedClaims.submittedOutsideDateRangeTotalCost | currency : '' : 'symbol' : '1.0-0' }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm">Submitted outside date range and accepted within date range</div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-3 col-xxl-2 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0 pe-1">
              <h2 class="card-title">Rejected Claims</h2>
            </div>
            <div class="col-auto ms-auto ps-0">
              <div class="d-flex stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-x">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>
          </div>
          <div class="d-inline-block my-0">
            <span id="submittedInRejectedCount"
                  class="display-6 text-warning pe-1">{{ ClaimStats.rejectedClaims.count }}</span>
            <span id="submittedInRejectedAmount"
                  class="h3">{{ ClaimStats.rejectedClaims.totalCost | currency : '' : 'symbol' : '1.0-0' }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm mb-3">Submitted and rejected within date range</div>
          <div class="d-inline-block my-0">
            <span id="submittedOutRejectedCount"
                  class="display-6 text-warning pe-1">{{ ClaimStats.rejectedClaims.submittedOutsideDateRangeCount }}</span>
            <span id="submittedOutRejectedAmount"
                  class="h3">{{ ClaimStats.rejectedClaims.submittedOutsideDateRangeTotalCost | currency : '' : 'symbol' : '1.0-0' }}</span>
          </div>
          <div class="fs-5 fw-bold text-muted lh-sm">Submitted outside date range and rejected within date range</div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6 col-xxl-2 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0 pe-1">
              <h2 class="card-title">Accepted Claims Resolution Time</h2>
            </div>
            <div class="col-auto ms-auto ps-0">
              <div class="d-flex stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-clock">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
              </div>
            </div>
          </div>
          <div class="mt-3 mb-2 lh-sm">
            <div id="valueAcceptedResolutionTime"
                 class="display-6 text-success mb-0">{{ ClaimStats.approvedClaims.businessMinutesToResolve / 60 | number: '1.1-1' }}
            </div>
            <div class="fs-5 fw-bold text-muted">Business Hours</div>
          </div>
          <hr class="my-2">
          <div class="my-2 lh-sm">
            <div id="valueAcceptedFirstViewTime"
                 class="h1 fw-normal text-success mb-0">{{ ClaimStats.approvedClaims.businessMinutesToFirstViewed / 60 | number: '1.1-1' }}
            </div>
            <div class="fs-5 fw-bold text-muted">Business Hours to First Viewing</div>
          </div>
          <div class="my-2 lh-sm">
            <div id="valueAcceptedSinceFirstViewTime"
                 class="h1 fw-normal text-success mb-0">{{ ClaimStats.approvedClaims.businessMinutesSinceFirstViewed / 60 | number: '1.1-1' }}
            </div>
            <div class="fs-5 bw-bold text-muted">Business Hours Since First Viewing</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 col-lg-6 col-xxl-2 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <div class="row">
            <div class="col mt-0 pe-1">
              <h2 class="card-title">Rejected Claims Resolution Time</h2>
            </div>
            <div class="col-auto ms-auto ps-0">
              <div class="d-flex stat stat-sm">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-clock">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
              </div>
            </div>
          </div>
          <div class="mt-3 mb-2 lh-sm">
            <div id="valueRejectedResolutionTime"
                 class="display-6 text-warning mb-0">{{ ClaimStats.rejectedClaims.businessMinutesToResolve / 60 | number: '1.1-1' }}
            </div>
            <div class="fs-5 fw-bold text-muted">Business Hours</div>
          </div>
          <hr class="my-2">
          <div class="my-2 lh-sm">
            <div id="valueRejectedFirstViewTime"
                 class="h1 fw-normal text-warning mb-0 me-1">{{ ClaimStats.rejectedClaims.businessMinutesToFirstViewed / 60 | number: '1.1-1' }}
            </div>
            <div class="fs-5 fw-bold text-muted">Business Hours to First Viewing</div>
          </div>
          <div class="my-2 lh-sm">
            <div id="valueRejectedSinceFirstViewTime"
                 class="h1 fw-normal text-warning mb-0 me-1">{{ ClaimStats.rejectedClaims.businessMinutesSinceFirstViewed / 60 | number: '1.1-1' }}
            </div>
            <div class="fs-5 fw-bold text-muted">Business Hours Since First Viewing</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-12 d-flex">
      <div class="card flex-fill">
        <div class="card-header pb-0">
          <h2 class="card-title mb-0">Claims Submitted within Date Range and Status Changed within Date Range</h2>
        </div>
        <div class="p-4">
          <canvas id="SubmittedWithinDateRangeChart">{{ chartWithinDateRange }}</canvas>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-striped table-no-bottom-border w-100 mb-2">
            <thead>
            <tr>
              <th class="p-0"></th>
              <th class="p-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Submitted (Not Yet Viewed)</td>
              <td class="text-end">{{ClaimStats.statusWithinDateRange.submitted}}</td>
            </tr>
            <tr>
              <td>Viewed</td>
              <td class="text-end">{{ClaimStats.statusWithinDateRange.viewed}}</td>
            </tr>
            <tr>
              <td>Accepted</td>
              <td class="text-end">{{ClaimStats.statusWithinDateRange.approved}}</td>
            </tr>
            <tr>
              <td>Rejected</td>
              <td class="text-end">{{ClaimStats.statusWithinDateRange.rejected}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th>Total</th>
              <th class="text-end">{{statusWithinDateRangeTotal}}</th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-lg-12 d-flex">
      <div class="card flex-fill">
        <div class="card-header pb-0">
          <h2 class="card-title mb-0">Claims Having Status Changed within Date Range</h2>
        </div>
        <div class="p-4">
          <canvas id="SubmittedOutsideDateRangeChart" >{{ chartOutsideDateRange }}</canvas>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-striped table-no-bottom-border w-100 mb-2">
            <thead>
            <tr>
              <th class="p-0"></th>
              <th class="p-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Submitted (Not Yet Viewed)</td>
              <td class="text-end">{{ClaimStats.statusOutsideDateRange.submitted}}</td>
            </tr>
            <tr>
              <td>Viewed</td>
              <td class="text-end">{{ClaimStats.statusOutsideDateRange.viewed}}</td>
            </tr>
            <tr>
              <td>Accepted</td>
              <td class="text-end">{{ClaimStats.statusOutsideDateRange.approved}}</td>
            </tr>
            <tr>
              <td>Rejected</td>
              <td class="text-end">{{ClaimStats.statusOutsideDateRange.rejected}}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th>Total</th>
              <th class="text-end">{{statusOutsideDateRangeTotal}}</th>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-auto">
              <h2 class="card-title">
                Claims by Adjuster<br>
                <small class="text-uppercase fw-bold text-muted">{{dateRangePicker?.startDate.format('MMMM D, YYYY')}} &mdash; {{dateRangePicker?.endDate.format('MMMM D, YYYY')}}</small>
              </h2>
            </div>
            <div class="col-auto ms-auto text-end">
              <button class="btn btn-secondary shadow-sm" (click)="downloadAdjusterStats()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download align-middle me-1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                Download CSV
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm table-striped table-hover table-no-bottom-border" datatable [dtOptions]="dtAdjusterOptions">
              <thead>
              <tr>
                <th>Adjuster</th>
                <th class="text-end">Accepted Time</th>
                <th class="text-end">Rejected Time</th>
                <th class="text-end">Accepted</th>
                <th class="text-end">Rejected</th>
                <th class="text-end">Unresolved</th>
                <th class="text-end">Total</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let adjuster of ClaimStats.adjusters.adjusters">
                <td class="pe-sort">{{adjuster.firstName}} {{adjuster.lastName}}</td>
                <td class="text-end pe-sort">{{adjuster.acceptedMinutes / 60 | number: '1.1-1'}} h</td>
                <td class="text-end pe-sort">{{adjuster.rejectedMinutes / 60 | number: '1.1-1'}} h</td>
                <td class="text-end pe-sort">{{adjuster.acceptedCount | number: '1.0-0'}}</td>
                <td class="text-end pe-sort">{{adjuster.rejectedCount | number: '1.0-0'}}</td>
                <td class="text-end pe-sort">{{adjuster.unresolvedCount | number: '1.0-0'}}</td>
                <td class="text-end pe-sort fw-bolder">{{adjuster.assignedCount | number: '1.0-0'}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th>Total</th>
                <th class="text-end pe-sort">{{ClaimStats.adjusters.acceptedMinutes / 60 | number: '1.1-1'}} h</th>
                <th class="text-end pe-sort">{{ClaimStats.adjusters.rejectedMinutes / 60 | number: '1.1-1'}} h</th>
                <th class="text-end pe-sort">{{ClaimStats.adjusters.acceptedCount | number: '1.0-0'}}</th>
                <th class="text-end pe-sort">{{ClaimStats.adjusters.rejectedCount | number: '1.0-0'}}</th>
                <th class="text-end pe-sort">{{ClaimStats.adjusters.unresolvedCount | number: '1.0-0'}}</th>
                <th class="text-end pe-sort">{{ClaimStats.adjusters.total | number: '1.0-0'}}</th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-auto">
              <h2 class="card-title">
                Claims by Repair Center<br>
                <small class="text-uppercase fw-bold text-muted">{{dateRangePicker?.startDate.format('MMMM D, YYYY')}} &mdash; {{dateRangePicker?.endDate.format('MMMM D, YYYY')}}</small>
              </h2>
            </div>
            <div class="col-auto ms-auto text-end">
              <button class="btn btn-secondary shadow-sm" (click)="downloadRepairCenterStats()">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download align-middle me-1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                Download CSV
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-sm table-striped table-hover table-no-bottom-border" datatable [dtOptions]="dtRepairCenterOptions">
              <thead>
              <tr>
                <th>Name</th>
                <th>City</th>
                <th>Province / State</th>
                <th class="text-end">Number of Claims</th>
                <th class="text-end">Retail Amount</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let repairCentre of ClaimStats.repairCentres.repairCentres">
                <td>{{repairCentre.name}}</td>
                <td>{{repairCentre.city}}</td>
                <td>{{repairCentre.province}}</td>
                <td class="text-end pe-sort">{{repairCentre.total | number: '1.0-0'}}</td>
                <td class="text-end pe-sort fw-bolder">{{repairCentre.retailTotal | currency : '' : '' : '1.0-0'}}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th>Total</th>
                <th></th>
                <th></th>
                <th class="text-end pe-sort">{{ClaimStats.repairCentres.total | number: '1.0-0'}}</th>
                <th class="text-end pe-sort">{{ClaimStats.repairCentres.retailTotal | currency : '' : '' : '1.0-0'}}</th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>









</ng-container>




