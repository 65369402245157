<hr class="mb-1 mt-0 text-black">
<div class="mb-4">
  <span class="h4">Message to Global Warranty</span>
  <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#messagesExpandCollapse" aria-expanded="true" aria-controls="messagesExpandCollapse">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
  </button>
</div>
<div id="messagesExpandCollapse" class="collapse show mb-5">
  <p class="mb-0">Add any questions or comments here. An adjuster from Global will respond to any inquiry.</p>
  <p class="mt-0 mb-2">You can see the response in the <strong>Messages</strong> section when viewing the claim after it is submitted.</p>
  <textarea class="form-control mt-1 mb-3" rows="3" (keyup)="updateMessage()" [(ngModel)]="claimDto.initialMessageFromRepairCentreToGlobal"></textarea>
</div>
