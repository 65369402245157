<ng-container *ngIf="!loading">
  <fieldset>
    <label class="form-label text-nowrap lh-sm mb-0">Claim Status <span class="required">*</span></label>
    <select class="form-select" (change)="updateStatus()" [(ngModel)]="claimDto!.status" required>
      <option value=0 selected>[Select...]</option>
      <ng-container *ngFor="let status of statuses">
        <option [value]="status">{{status}}</option>
      </ng-container>
    </select>
    <div class="invalid-feedback lh-sm">Please select a status.</div>
  </fieldset>
</ng-container>
<app-spinner [show]="loading"></app-spinner>
