import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DeleteRepairService {

  constructor(private httpClient: HttpClient) { }

  public deleteRepair(repairId: number) {
    return this.httpClient.delete(`${environment.apiServer}/DeleteRepair`, {params: {repairId: repairId}});
  }
}
