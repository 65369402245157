<ng-container *ngIf="claim">
  <div class="lh-sm me-4">
    <p class="fw-normal fs-2 text-uppercase mb-0">{{claim.vehicle.vin}}</p>
    <p class="mt-0 mb-3 mb-xl-0"><span class="d-inline-block fw-normal opacity-75 me-2">Policy Number</span> <span class="fw-bold fs-4">{{claim.warranty.warrantyId}}</span></p>
  </div>
  <div class="lh-sm me-0 me-md-4 mb-3 mb-md-0">
    <p class="fw-bolder fs-2 m-0">{{claim.vehicle.year}} {{claim.vehicle.model}} {{claim.vehicle.make}}</p>
    <p class="my-0 text-uppercase">{{claim.vehicle.trim}}</p>
  </div>
</ng-container>
