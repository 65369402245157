<ng-container *ngIf="vehicle">
  <ng-container *ngFor="let application of vehicle.applications">
    <div class="row">
      <div class="col-12 text-center pt-3">
        <h3 class="text-info m-0">{{vehicle.year}} {{vehicle.make}} {{vehicle.model}}  {{vehicle.trim}}</h3>
        <p class="text-uppercase fs-4 mt-0 mb-2" [innerHTML]="vehicle.vin | highlighter:searchTerm"></p>
        <p class="small m-0">SOLD BY</p>
        <p class="text-uppercase mt-n1 fs-4 fw-bold">{{application.vendorName}}</p>
      </div>
      <ng-container *ngFor="let warranty of application.warranties">
        <div class="col-sm-6 d-flex flex-column">
          <div class="bg-light rounded-top text-center flex-fill pt-3 px-3">
            <h3 class="m-0">{{warranty.coverageType}}</h3>
            <p class="text-uppercase fs-4 mt-0">{{warranty.label}}</p>
            <p class="m-0 fw-bolder">{{warranty.status}}</p>
            <p class="m-0">{{warranty.expiryDate}}</p>
            <p class="m-0" [innerHTML]="warranty.warrantyId.toString() | highlighter:searchTerm"></p>
            <p class="mt-0">${{warranty.deductible}} Deductible</p>
          </div>
          <button class="btn btn-primary" [disabled]="startingNewClaim" (click)="startNewClaim(warranty.warrantyId)">Start Automotive Claim</button>

        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
