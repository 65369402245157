<div class="modal-header">
  <h4 class="modal-title ms-3">Delete Claim</h4>
  <button type="button" class="btn-close me-2" data-bs-dismiss="modal" aria-label="Close" (click)="this.activeModal.close()"></button>
</div>

<div class="modal-body mx-3">
  <div id="deleteInventoryTaskError" class="alert alert-danger alert-outline-coloured d-none" role="alert">
    <div class="alert-icon"><i data-feather="feather-alert-octagon"></i></div>
    <div class="alert-message"><strong>Unable to delete task.</strong> Error code <span></span>.</div>
  </div>
  <p>Do you wish to delete this claim?</p>
  <table class="table table-sm table-no-bottom-border">
    <thead><tr><th></th><th></th></tr></thead>
    <tbody>
    <tr><th>Customer</th><td>{{claim.customerName}}</td></tr>
    <tr><th>Vehicle</th><td>{{claim.ymmt}}</td></tr>
    <tr><th>VIN</th><td>{{claim.vin}}</td></tr>
    <tr><th>Type</th><td>{{claim.coverageType}}</td></tr>
    </tbody>
  </table>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-danger position-relative me-3" (click)="deleteClaim()" *ngIf="!isDeleting">
    <span class="spinner fade spinner-border spinner-border-sm position-absolute top-0 bottom-0 start-0 end-0 m-auto" role="status" aria-hidden="true"></span>
    <span class="text fade show"><i class="me-1" data-feather="trash"></i>Delete</span>
  </button>
  <app-spinner [show]="isDeleting"></app-spinner>
</div>
