import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SetUpdateForRepairCentreService {

  constructor(private httpClient: HttpClient) { }

  public SetUpdateForRepairCentre(claimId: number) {
    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/SetUpdateForRepairCentre`, {claimId: claimId});
  }
}
