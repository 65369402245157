<div class="internal-use mt-4">
  <div class="row">
    <fieldset class="col-12 mb-3">
      <label class="form-label text-nowrap mb-0">Claim Status <span class="required">*</span></label>
      <select class="form-select" required>
        <option value="Accepted">Accepted</option>
        <option value="Rejected">Rejected</option>
        <option value="Deleted">Deleted</option>
      </select>
    </fieldset>
    <fieldset class="col-12 mb-3">
      <label class="form-label text-nowrap mb-0">Claim Authorization Number</label>
      <input class="form-control" type="text">
    </fieldset>
    <fieldset class="col-12 mb-3">
      <label class="form-label text-nowrap mb-0">Internal Comments</label>
      <textarea class="form-control" rows="5"></textarea>
      <p class="text-muted lh-sm mt-1 mb-0">Internal comments are not visible to the repair center.</p>
    </fieldset>
  </div>
</div>
