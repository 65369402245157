<div class="modal-header">
  <h4 class="modal-title ms-3">Your Claim Has Been Submitted!</h4>
</div>

<div class="modal-body py-4">
    <div class="container-fluid px-3">
      <div class="bg-light rounded-3 p-3">

        <div class="text-center" *ngIf="isFullyPreApproved">
          <svg class="text-success" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          <div class="text-success fs-4 fw-bolder lh-sm mt-1">PRE-APPROVED!</div>
          <p class="mt-2 lh-md">Your claim has been pre-approved, please proceed with the repair and an adjuster will be contact with you.</p>
        </div>

        <div class="text-center" *ngIf="isPartiallyPreApproved">
          <svg class="text-success" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
          <div class="text-success fs-4 fw-bolder lh-sm mt-1">PARTIALLY <span class="text-nowrap">PRE-APPROVED</span></div>
          <p class="mt-2 lh-md">Your claim has been partially pre-approved. Please view the claim to see the pre-approval amount. An adjuster will be contact with you.</p>
        </div>

        <div class="text-center" *ngIf="isNotPreApproved">
          <svg class="text-warning" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
          <div class="text-warning fs-4 fw-bolder lh-sm mt-1">PENDING APPROVAL</div>
          <p class="mt-2 lh-md">Please wait on repair work until confirmation from Global.</p>
        </div>

      </div>
      <div class="text-center mt-4 mb-3">
        <button class="btn btn-primary me-3" (click)="returnToClaimsList()"><i class="me-2" data-feather="corner-down-left"></i>Back to Claims List</button>
        <button class="btn btn-primary" (click)="viewClaim()"><i class="me-2" data-feather="eye"></i>View This Claim</button>
      </div>
    </div>
</div>
