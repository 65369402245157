import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UpdateStatusService {

  constructor(private httpClient: HttpClient) {
  }

  public UpdateStatus(claimId: number, status: string) {
    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/UpdateStatus`, {claimId, status});
  }

}
