import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {GetAttachmentService} from "./services/get-attachment.service";
import {DomSanitizer, SafeHtml, SafeUrl} from "@angular/platform-browser";
import {NgClass, NgIf} from "@angular/common";
import {AttachmentFile} from "../../../models/attachmentFile";
import Utils from "../../../helpers/utils";

@Component({
  selector: 'app-view-attachment-modal',
  standalone: true,
  imports: [
    NgClass,
    NgbTooltip,
    NgIf
  ],
  templateUrl: './view-attachment-modal.component.html',
  styleUrl: './view-attachment-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ViewAttachmentModalComponent implements OnInit {
  @Input() attachments: AttachmentFile[] = [];
  @Input() selectedAttachment?: AttachmentFile;

  @Input() blobUrl: string | null = null;
  @Input() attachmentIframeUrl: SafeUrl | null = null;
  @Input() attachmentImgUrl: SafeUrl | null = null;
  @Input() attachmentName?: string;
  @Input() attachmentIcon?: SafeHtml;
  @Input() attachmentSize?: string;

  public loading: boolean = true;
  public isZoomed: boolean = false;
  private currentIndex: number = 0;

  constructor(
    private getAttachmentService: GetAttachmentService,
    private sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    if (this.selectedAttachment) {
      this.currentIndex = this.attachments.findIndex(item => item.fileId === this.selectedAttachment?.fileId);
      this.getAttachment(this.attachments[this.currentIndex]);
    }
  }

  public viewNextAttachment() {
    this.currentIndex++;
    if (this.currentIndex === this.attachments.length) {
      this.currentIndex = 0;
    }
    this.getAttachment(this.attachments[this.currentIndex]);
  }

  public viewPreviousAttachment() {
    this.currentIndex--;
    if (this.currentIndex === -1) {
      this.currentIndex = this.attachments.length > 0 ? this.attachments.length - 1 : 0;
    }
    this.getAttachment(this.attachments[this.currentIndex]);
  }

  private getAttachment(file: AttachmentFile) {
    this.loading = true;
    if (this.blobUrl) {
      URL.revokeObjectURL(this.blobUrl);
    }
    this.getAttachmentService.getAttachment(file.fileId).subscribe({
      next: (response) => {
        const blob = new Blob([response], {type: file.type});
        this.blobUrl = window.URL.createObjectURL(blob);
        if (file.type.startsWith('image')) {
          this.attachmentImgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);
          this.attachmentIframeUrl = null;
        } else {
          this.attachmentImgUrl = null;
          this.attachmentIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.blobUrl);
        }
        this.attachmentName = file.name;
        this.attachmentIcon = file.icon;
        this.attachmentSize = Utils.formatBytes(file.size);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  public closeAttachmentModal() {
    if (this.blobUrl) {
      URL.revokeObjectURL(this.blobUrl);
    }
    this.activeModal.close();
  }

  public toggleZoom() {
    this.isZoomed = !this.isZoomed;
  }

  public downloadAttachment() {
    const link = document.createElement('a');
    link.href = this.blobUrl ?? '';
    link.download = this.attachmentName ?? '';
    link.click();
  }

}
