<body>
<div class="wrapper">
  <div class="main">
    <app-nav-menu></app-nav-menu>
    <main class="content">
      <div class="container-fluid p-0">
        <router-outlet></router-outlet>
      </div>
    </main>
    <p class="m-auto">Build {{BuildVersion.buildVersion}}</p>
  </div>
</div>
</body>
