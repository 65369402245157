
<nav class="navbar navbar-expand bg-dark fixed-top">
  <div class="navbar-brand">
    <img class="pe-3 border-end d-none d-md-inline-block" src="/assets/img/logo.gw.wide.svg" alt="Global Warranty">
    <div class="d-inline-block lh-md pe-0 py-0 ps-0 ps-md-4">
      <a (click)="appTitleClick()"><span class="fs-3 fw-bold">Online Claims</span></a>
      <span class="badge ms-3 px-2 {{environment.environmentHumanReadablePhraseClass}}">{{environment.environmentHumanReadablePhrase}}</span>
    </div>
  </div>
  <div class="navbar-collapse collapse">
    <ul class="navbar-nav navbar-align">
      <li *ngIf="globalViewModelDto && globalViewModelDto.repairCentre && globalViewModelDto.repairCentre.name && globalViewModelDto.repairCentre.address && globalViewModelDto.repairCentre.city && globalViewModelDto.repairCentre.province" class="d-none d-lg-inline-block nav-item repair-center-address lh-md my-auto pe-5">
        <strong>{{globalViewModelDto.repairCentre.name}}</strong><br>
        {{globalViewModelDto.repairCentre.address}}, {{globalViewModelDto.repairCentre.city}}, {{globalViewModelDto.repairCentre.province}}
      </li>
      <li class="nav-item dropdown">
        <a class="nav-icon dropdown-toggle d-inline-block d-md-none p-0" href="#" data-bs-toggle="dropdown">
          <img src="/assets/img/avatar.png" class="avatar img-fluid rounded-circle" alt="{{this.name}}">
        </a>
        <a class="nav-link dropdown-toggle d-none d-md-inline-block" href="#" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="/assets/img/avatar.png" class="avatar img-fluid rounded-circle me-1" alt="{{this.name}}">
          <span class="px-2">{{this.name}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a *ngIf="isGlobalEmployee" class="dropdown-item" href="/stats">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pie-chart align-middle me-1"><path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path><path d="M22 12A10 10 0 0 0 12 2v10z"></path></svg>
            Claims Overview
          </a>
          <hr *ngIf="isGlobalEmployee" class="my-1">
          <a class="dropdown-item" (click)="logout()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out align-middle me-1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
            Sign Out
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>

