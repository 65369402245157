<ng-container *ngIf="availablePartGroups.length">
<div class="row">
  <div class="col-sm-12 col-xl-9 px-3">
    <hr class="mb-1 mt-0 text-black">
    <div class="mb-4">
      <span class="h4">Repair Work</span>
      <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#repairWorkExpandCollapse" aria-expanded="true" aria-controls="repairWorkExpandCollapse">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
      </button>
    </div>
  </div>
</div>
<div id="repairWorkExpandCollapse" class="collapse show mb-4">
  <div class="row">
    <div class="col-sm-12 col-xl-9 px-3">
      <div class="row mb-3">
        <app-date-of-occurrence class="col-sm-6 col-lg-3 mt-auto mb-3" [loadedDateOfOccurrence]="ClaimDto!.dateOfOccurrence" [claimId]="ClaimDto!.claimId" [claimCreatedDateTime]="ClaimDto?.createdDateTime"></app-date-of-occurrence>
        <app-odometer class="col-sm-6 col-lg-4 mt-auto mb-3" [claimDto]="ClaimDto!"></app-odometer>
        <app-door-rate [claimDto]="ClaimDto" class="col-sm-6 col-lg-3 mb-3"></app-door-rate>
        <app-towing class="col-sm-6 col-lg-2 mt-auto mb-3 order-last order-lg-0" [claimId]="ClaimDto!.claimId" [towing]="ClaimDto!.towing!"></app-towing>
        <div class="offset-lg-3 col-sm-6 col-lg-4 mt-0 mt-lg-n2">
          <label class="form-label text-nowrap mb-1 lh-md">Last Known Odometer Reading</label>
          <div class="lh-1">
            <span class="d-inline-block fs-2 me-1">{{ClaimDto!.lastKnownMaintenanceOdometer.reading | number}}</span> <span class="d-inline-block text-lowercase fs-5 me-2">{{ClaimDto!.lastKnownMaintenanceOdometer.odometerUnitLabel}}</span>
            <span class="d-inline-block fw-bold fs-4">{{ClaimDto!.lastKnownMaintenanceOdometer.createdDate | date: 'longDate' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-spinner [show]="loadingRepairs" [center]="true"></app-spinner>

  <ng-container *ngFor="let repair of Repairs">
    <app-repair
      [claim]="ClaimDto"
      [IsLocked]="IsLocked"
      [availablePartGroups]="availablePartGroups"
      [repair]="repair"
      [IsAdjusterView]="IsAdjusterView"
      (totalUpdated)="calculateTotal()"
      [RepairAtTimeOfSubmission]="repairsAtTimeOfSubmission == null ? null : repairsAtTimeOfSubmission[repair.repairId]"
      (repairDeleted)="removeRepairFromInMemoryArray($event)">
    </app-repair>
  </ng-container>

  <app-spinner [show]="addingRepair" [center]="true"></app-spinner>

  <div class="row" *ngIf="!IsLocked">
    <div class="col-xl-9 px-3 mb-xl-2">
      <div class="bg-light rounded p-3 mx-n3 text-center">
        <button (click)="addRepair()" class="btn btn-pill btn-lg btn-outline-primary btn-bg-white my-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather-plus me-1">
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          <span class="d-block mx-4">Add a Repair Item</span>
        </button>
      </div>
    </div>
  </div>

</div>
</ng-container>
<app-spinner [show]="!availablePartGroups.length" [center]="true"></app-spinner>
