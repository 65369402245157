import { Injectable } from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AssignToMeService {

  constructor(private httpClient: HttpClient) {
  }

  assignToMe(claimId: number) {
    return this.httpClient.put(`${environment.apiServer}/Adjuster/Claim/AssignToMe`, {
      claimId: claimId,
    });
  }
}
