import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {NavMenuComponent} from './nav-menu/nav-menu.component';
import {AuthHttpConfigModule} from './auth/auth-http-config.module';
import {AuthInterceptor, AuthModule, AutoLoginPartialRoutesGuard, LogLevel} from "angular-auth-oidc-client";
import {ClaimComponent as RepairCentreClaimComponent} from './repair-centre/claim/claim.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {VehicleInformationComponent} from './claim/components/vehicle-information/vehicle-information.component';
import {WarrantyInformationComponent} from "./claim/components/warranty-information/warranty-information.component";
import {CustomerInformationComponent} from './claim/components/customer-information/customer-information.component';
import {RepairsComponent} from "./claim/components/repairs/repairs.component";
import {RepairComponent} from './claim/components/repairs/components/repair/repair.component';
import {HighlighterPipe} from "./helpers/highlighter/highlighter.pipe";
import {SpinnerComponent} from './spinner/spinner.component';
import {DndDirective} from './claim/components/file-upload/directives/dnd.directive';
import {FileUploadComponent} from './claim/components/file-upload/file-upload.component';
import {DataTablesModule} from "angular-datatables";
import {TotalsComponent} from "./claim/components/totals/totals.component";
import {MessagesComponent} from "./claim/components/messages/messages.component";
import {CallbackComponent} from "./auth/callback/callback.component";
import {DeleteClaimComponent} from './repair-centre/delete-claim/delete-claim.component';
import {isGlobalEmployee} from "./auth/is-global-employee";
import {StartupComponent} from './startup/startup.component';
import {
  ClaimsListComponent as AdjusterClaimsListComponent
} from "./adjuster/components/claims-list/claims-list.component";
import {
  ClaimsListComponent as RepairCentreClaimsListComponent
} from "./repair-centre/claims-list/claims-list.component";
import {VinSearchComponent} from "./repair-centre/vin-search/vin-search.component";
import {VehicleWarrantyComponent} from "./repair-centre/vin-search/vehicle-warranty/vehicle-warranty.component";
import {AuthorizationComponent} from "./adjuster/components/authorization/authorization.component";
import {StatsComponent} from "./adjuster/components/stats/stats.component";
import {StatsComponent as StatsViewComponent} from "./stats/stats.component";
import {ClaimComponent as AdjusterClaimComponent} from "./adjuster/components/claim/claim.component";
import {
  ClaimAuthorizationNumberComponent
} from './adjuster/components/claim/claim-authorization-number/claim-authorization-number.component';
import {AssignToComponent} from './adjuster/components/claim/assign-to/assign-to.component';
import {StatusComponent} from "./adjuster/components/claim/status/status.component";
import {InternalCommentsComponent} from "./adjuster/components/claim/internal-comments/internal-comments.component";
import {DateOfOccurrenceComponent} from './claim/components/date-of-occurrence/date-of-occurrence.component';
import {OdometerComponent} from './claim/components/odometer/odometer.component';
import {TowingComponent} from './claim/components/towing/towing.component';
import {StickyTotalsComponent} from "./claim/components/sticky-totals/sticky-totals.component";
import {StickyHeaderComponent} from "./adjuster/components/claim/sticky-header/sticky-header.component";
import {NgxMaskDirective, provideEnvironmentNgxMask} from "ngx-mask";
import {
  InitialMessageFromRepairCentreToGlobalComponent
} from "./repair-centre/claim/initial-message-from-repair-centre-to-global/initial-message-from-repair-centre-to-global.component";
import {UpdateButtonComponent} from "./adjuster/components/claim/update-button/update-button.component";
import {DeductibleComponent} from "./adjuster/components/claim/deductible/deductible.component";
import {StatusBadgeComponent} from "./repair-centre/claim/status-badge/status-badge.component";
import {DoorRateComponent} from "./claim/components/door-rate/door-rate.component";
import {IsClaimTestDataComponent} from "./adjuster/components/claim/is-claim-test-data/is-claim-test-data.component";
import {UnauthorizedComponent} from "./auth/unauthorized/unauthorized.component";
import {provideDaterangepickerLocale} from "ngx-daterangepicker-bootstrap";

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    AdjusterClaimComponent,
    RepairCentreClaimComponent,
    VinSearchComponent,
    VehicleInformationComponent,
    WarrantyInformationComponent,
    CustomerInformationComponent,
    InternalCommentsComponent,
    RepairsComponent,
    RepairComponent,
    VehicleWarrantyComponent,
    HighlighterPipe,
    SpinnerComponent,
    DndDirective,
    FileUploadComponent,
    RepairCentreClaimsListComponent,
    TotalsComponent,
    AuthorizationComponent,
    MessagesComponent,
    StatusComponent,
    DeleteClaimComponent,
    AdjusterClaimsListComponent,
    UpdateButtonComponent,
    DeductibleComponent,
    StatsComponent,
    StartupComponent,
    ClaimAuthorizationNumberComponent,
    AssignToComponent,
    DateOfOccurrenceComponent,
    OdometerComponent,
    TowingComponent,
    StickyTotalsComponent,
    StickyHeaderComponent,
    StatusBadgeComponent,
    DoorRateComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      {path: '', redirectTo: '/startup', pathMatch: 'full'},
      {path: 'claim/:claimId', component: RepairCentreClaimComponent, canActivate: [AutoLoginPartialRoutesGuard]},
      {
        path: 'claim-list',
        component: RepairCentreClaimsListComponent,
        canActivate: [AutoLoginPartialRoutesGuard]
      },
      {path: 'adjuster', canActivate: [AutoLoginPartialRoutesGuard, isGlobalEmployee], children: [
          {path: 'claim-list', component: AdjusterClaimsListComponent},
          {path: 'claim/:claimId', component: AdjusterClaimComponent},
      ]},
      {path: 'stats', component: StatsViewComponent, canActivate: [AutoLoginPartialRoutesGuard, isGlobalEmployee]},
      {path: 'vin-search', component: VinSearchComponent, canActivate: [AutoLoginPartialRoutesGuard]},
      {path: 'startup', component: StartupComponent, canActivate: [AutoLoginPartialRoutesGuard]},
      {path: 'callback', component: CallbackComponent},
      {path: 'unauthorized', component: UnauthorizedComponent},
      {path: '**', component: UnauthorizedComponent, pathMatch: 'full'},
    ], {scrollPositionRestoration: 'enabled'}),
    AuthHttpConfigModule,
    ReactiveFormsModule,
    NgbModule,
    NgxMaskDirective,
    DataTablesModule,
    AuthModule.forRoot({
      config: {
        authority: 'https://sso.globalwarranty.net',
        // authority: 'https://localhost:5001',
        redirectUrl: `${window.location.origin}/callback`,
        postLogoutRedirectUri: window.location.origin,
        clientId: 'claims',
        scope: 'openid api1 profile offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        unauthorizedRoute: '/unauthorized',
        forbiddenRoute: '/unauthorized',
        postLoginRoute: '/startup',
        logLevel: LogLevel.Error,
        secureRoutes: ['https://localhost:7084/', 'https://alpha.claims.api.globalwarranty.net/', 'https://claim.api.globalwarranty.net/'],
        historyCleanupOff: true,
      },
    }),
    InitialMessageFromRepairCentreToGlobalComponent,
    NgOptimizedImage,
    IsClaimTestDataComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    provideEnvironmentNgxMask(),
    provideDaterangepickerLocale({
      separator: ' - ',
      applyLabel: 'Okay',
    }),
    DecimalPipe
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
}

