<hr class="mb-1 mt-0 text-black">
<div class="mb-4">
    <span class="h4">Internal Notes</span>
    <button class="btn btn-link btn-collapse float-end p-0" type="button" data-bs-toggle="collapse" data-bs-target="#internalNotesExpandCollapse" aria-expanded="true" aria-controls="internalNotesExpandCollapse">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"></path></svg>
    </button>
    <app-spinner [show]="false" [center]="false"></app-spinner>
</div>
<div id="internalNotesExpandCollapse" class="collapse show mb-4">
    <textarea [(ngModel)]="claimDto!.internalNotes" (keyup)="updateInternalNotes()" class="form-control" rows="5"></textarea>
    <small class="d-block pb-3">Internal comments are not visible to the repair center.</small>
</div>
